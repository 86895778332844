<template>
<div>
  <Header1></Header1>
  <Header></Header>
  <!-- banner图 -->
  <div class="banner" :style="{backgroundImage: 'url('+ getBgImgAbout() +')', height: getHeight()}">
    <div class="wrap" :style="{height: getHeight()}">
      <div class="word-wrapper">
        <span class="title">关于</span> <br>
      </div>
    </div>
  </div>
  <!-- content -->
  <div class="content">
    <!-- 关于本站 -->
    <div class="biaoti">关于本站</div> 
    <div class="about_website">
      <div class="item" v-for="item in aboutInfo.aboutWebsite" :key="item.index"> <span>{{item}}</span> </div>
      <!-- <div class="item"> <span>本站使用前后端分离开发和部署，前台用到了vue、axios、vuex、router、element ui</span> </div>
      <div class="item"> <span>后台主要使用springboot、mybatis plus、mysql、nginx</span> </div>
      <div class="item"> <span>服务器使用阿里云ECS（1核2G1M），性能比较拉胯，但作为学习来讲也够用了，图片存储使用的是阿里云对象存储OSS</span> </div>
      <div class="item"> <span>小屋的天空主要是用来记录自己在平时学习和工作中的一些笔记，遇到的问题，以及各种随笔，方便自己随时查阅巩固，如果恰巧能帮到您，将是我的荣幸。</span> </div>
      <div class="item"><span>在开发中，前台ui和功能借鉴了诸多大佬的个人网站，都会在友链页面中一一展示，在此表示感谢！</span></div> -->
    </div>
    <!-- 关于作者 -->
    <div class="biaoti">关于作者</div> 
    <div class="about_me">
      <div class="item" v-for="item in aboutInfo.aboutMe" :key="item.index"> <span>{{item}}</span> </div>
      <!-- <div class="item"> <span>关于小wu</span> </div>
      <div class="item"> <span>2021届，软件工程，菜鸡一枚</span> </div>
      <div class="item"> <span>本来计划一个月完成开发并上线，但是到后面做的时候发现时间确实不太够，在开发过程中会遇到很多问题</span> </div>
      <div class="item"> <span>比如有一次发现服务器CPU使用率直接100%，花了很长时间才知道是由于我redis配置问题导致服务器被攻击沦为矿机（小伙伴们使用redis的时候一定要设置密码！），由于相关知识的匮乏，几乎花了一整个周末才解决问题（解决方案在主页有相关文章）。</span> </div>
      <div class="item"> <span>类似这样的小插曲还有很多，再加上现学各种知识，总之就是严重影响了进度，不过令人欣慰的是最终结果是符合预期的</span> </div>
      <div class="item"> <span>尽管我的小破站现在还有各种各样的不完美（加载慢，浏览体验并不友好，功能单一，逻辑...），但是这并不是它最终的状态，小wu的天空会持续更新。</span> </div> -->
    </div>
    <!-- 联系站长 -->
    <div class="biaoti">联系站长</div> 
    <div class="call_me" v-if="aboutInfo.callMe">
      <div class="item">
        <span>QQ</span> 
        <img :src="aboutInfo.callMe[0]" alt="">
      </div>
      <div class="item">
         <span>微信</span> 
         <img :src="aboutInfo.callMe[1]" alt="">
      </div>
      <div class="item">
         <span>邮箱</span> 
         <img :src="aboutInfo.callMe[2]" alt="">
      </div>
    </div>
    <!-- 小破站大事记 -->
    <div class="biaoti">小破站大事记</div>
    <div class="big_things_wrapper">
      <el-timeline>
        <el-timeline-item
          v-for="(activity, index) in activities"
          :key="index"
          :icon="activity.icon"
          :type="activity.type"
          :color="activity.color"
          :size="activity.size"
          :timestamp="activity.timestamp">
          {{activity.content}}
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
  <Version></Version>
  <el-backtop class="up"> <i class="el-icon-top"></i></el-backtop>
</div>
</template>


<script>
import Header1 from "../components/Header1.vue"  ;
import Header from "../components/Header.vue"  ;
import Version from "../components/Version.vue"  ;
export default {
  name : 'About' , 
  components:{Header1,Header,Version} , 
  data(){
    return {
      aboutInfo:{} , 
      aboutImg:"" , 
      activities: [{
        content: 'One year anniversary! 🎉',
        timestamp: '2022-11-26',
        // size: 'large',
        color: 'rgb(206,91,91)'
      } ,{
        content: '对前台做了美化',
        timestamp: '2022-08-27',
        // color: '#0bbd87'
      },{
        content: '修改了留言页面的逻辑，同时将留言添加到博客详情中，bugfix',
        timestamp: '2022-07-16',
        // color: '#0bbd87'
      },{
        content: '完善后台管理功能，bugfix',
        timestamp: '2022-02-08',
        // color: '#0bbd87'
      } ,{
        content: '正式发布（虽然还是很烂...  继续改ing）',
        timestamp: '2021-11-26',
        size: 'large',
        type: 'primary',
      },{
        content: '新增留言功能、加载等待提示、浏览量和运行时间，优化了对移动端的适配和浏览体验，优化了文章阅读体验，优化了整站ui及观感',
        timestamp: '2021-11-26',
        // size: 'large',
        color: '#0bbd87'
      } ,{
        content: '新增友链和关于页面，参考了诸多大佬的个人主页，进一步优化前台ui，同时增加了对移动端的适配',
        timestamp: '2021-11-06',
        // size: 'large',
        // type: 'primary',
        // icon: 'el-icon-more'
      }, {
        content: '编写了基本的后台管理功能，对前台ui做了较大的修改和美化，域名备案完成，解析域名并配置了ssl',
        timestamp: '2021-10-21',
        // color: '#0bbd87'
      }, {
        content: '编写了初版，实现了基本的登录、发表、查看、分类和归档且界面较丑，同时学习Linux，配置了一下服务器环境，将幼儿版系统部署。',
        timestamp: '2021-10-07',
        // size: 'large'
      }, {
        content: '计划写一个个人博客，既是对已掌握知识的反馈，同时也了解不足之处，并方便自己查阅笔记。购买了服务器和域名linwuaiwo.cn',
        timestamp: '2021-09-24'
      }]
    }
  } , 
  methods:{
    getHeight(){
      return window.innerHeight + 'px' ; 
    } , 
    // 获取关于信息
    getAbout(){
      this.$axios.get("/getAbout" ).then(res => {
        this.aboutInfo = res.data.data ; 
      }) ; 
    } , 
    // 获取关于的背景图片
    getAboutImg(){
      const _this = this ; 
      _this.$axios.get("/getBgAbout").then(res => {
        _this.aboutImg = res.data.data ; 
        // return res.data.data ; 
      }) ; 
    }  , 
    getBgImgAbout(){
      const _this = this ; 
      return _this.aboutImg ; 
    }
  } , 
  created(){
    this.getAbout() ; 
    this.getAboutImg() ; 
  }
}
</script>


<style scoped>
.banner{
  width: 100vw;
  height: 100vh;
  /* background-image: url("../img/k-on1.jpeg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* position: absolute;
  left: 0;
  top: 0;
  overflow: hidden; */
  opacity: 1;
  margin-bottom: 5rem;
}
.banner .wrap{
  width: 100vw;
  height: 100vh;
  background-color: rgba( 0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
}
.banner .wrap .word-wrapper{
  position: absolute;
  top: 35%;
}
.banner .wrap .word-wrapper .title{
  color: white;
  font-size: 40px;
}
.banner .wrap .word-wrapper .dec{
  color: white;
  font-size: 15px;
}


@media all and (min-width:850px) {
  .content{
    width: 55vw ; 
    /* background-color: pink; */
    /* border: 1px solid pink; */
    margin: 0 auto;
    /* margin-top: 4rem; */
    margin-bottom: 10rem;
    background: rgba(255, 255, 255, 0.5);
    padding-top: 1rem;
    padding-bottom: 10rem;
    border-radius: 5px;
  }
  .content .biaoti{
    height: 2.5rem;
    line-height: 2.5rem;
    padding-left: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    border-left: .4rem solid #333;
    margin-top: 6rem;
  }
  
  .content .about_website{
    margin: 1rem;
    border-radius: 5px;
    box-shadow: 1px 4px 15px rgb(125 147 178 / 32%);
    transition: all .3s;
    font-size: 1.4rem;
    background: rgba(255, 255, 255, 0.8);
  }
  .content .about_website:hover{
    box-shadow: 1px 4px 15px rgb(75 174 194 / 32%);
  }
  .content .about_website .item{
    padding: 1.5rem;
  }
  .content .about_me{
    margin: 1rem;
    border-radius: 5px;
    box-shadow: 1px 4px 15px rgb(125 147 178 / 32%);
    transition: all .3s;
    font-size: 1.4rem;
    background: rgba(255, 255, 255, 0.8);
  }
  .content .about_me:hover{
    box-shadow: 1px 4px 15px rgb(75 174 194 / 32%);
  }
  .content .about_me .item{
    padding: 1.5rem;
  }
  .content .call_me{
    margin: 1rem;
    box-shadow: 1px 4px 15px rgb(125 147 178 / 32%);
    margin-top: 1rem;
    transition: all .3s;
    display: flex;
    font-size: 1.4rem;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
  }
  .content .call_me:hover{
    box-shadow: 1px 4px 15px rgb(75 174 194 / 32%);
  }
  .content .call_me .item{
    /* border: 1px solid red; */
    box-sizing: border-box;
    /* margin-left: 1rem; */
    text-align: center;
    padding: 1.5rem;
  }
  .content .call_me .item img{
    width: 12rem;
    height: 12rem;
    display: block;
    background-color: pink;
    margin-top: 1rem;
  }
  .content .big_things_wrapper{
    margin: 1rem;
    box-shadow: 1px 4px 15px rgb(125 147 178 / 32%);
    margin-top: 1rem;
    transition: all .3s;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
  }
  .content .big_things_wrapper:hover{
    box-shadow: 1px 4px 15px rgb(75 174 194 / 32%);
  }
  .el-timeline{
    padding: 2rem;
  }
  .el-timeline-item{
    font-size: 1.4rem;
  }
}
@media all and (max-width:850px) {
  .content{
    width: 96vw ; 
    /* background-color: pink; */
    /* border: 1px solid pink; */
    margin: 0 auto;
    /* margin-top: 4rem; */
    margin-bottom: 10rem;
    background: rgba(255, 255, 255, 0.5);
    padding-top: 1rem;
    padding-bottom: 10rem;
    border-radius: 5px;
  }
  .content .biaoti{
    height: 2.5rem;
    line-height: 2.5rem;
    padding-left: 1rem;
    font-size: 1.4rem;
    font-weight: 600;
    color: #333;
    border-left: .4rem solid #333;
    margin-top: 6rem;
  }
  
  .content .about_website{
    margin: 1rem;
    border-radius: 5px;
    box-shadow: 1px 4px 15px rgb(125 147 178 / 32%);
    transition: all .3s;
    font-size: 1.3rem;
    background: rgba(255, 255, 255, 0.8);
  }
  .content .about_website:hover{
    box-shadow: 1px 4px 15px rgb(75 174 194 / 32%);
  }
  .content .about_website .item{
    padding: 1.5rem;
  }
  .content .about_me{
    margin: 1rem;
    border-radius: 5px;
    box-shadow: 1px 4px 15px rgb(125 147 178 / 32%);
    transition: all .3s;
    font-size: 1.3rem;
    background: rgba(255, 255, 255, 0.8);
  }
  .content .about_me:hover{
    box-shadow: 1px 4px 15px rgb(75 174 194 / 32%);
  }
  .content .about_me .item{
    padding: 1.5rem;
  }
  .content .call_me{
    margin: 1rem;
    box-shadow: 1px 4px 15px rgb(125 147 178 / 32%);
    margin-top: 1rem;
    transition: all .3s;
    display: flex;
    font-size: 1.4rem;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
  }
  .content .call_me:hover{
    box-shadow: 1px 4px 15px rgb(75 174 194 / 32%);
  }
  .content .call_me .item{
    /* border: 1px solid red; */
    box-sizing: border-box;
    /* margin-left: 1rem; */
    text-align: center;
    padding: 1.5rem;
  }
  .content .call_me .item img{
    width: 22vw;
    /* height: 12rem; */
    display: block;
    background-color: pink;
    margin-top: 1rem;
  }
  .content .big_things_wrapper{
    margin: 1rem;
    box-shadow: 1px 4px 15px rgb(125 147 178 / 32%);
    margin-top: 1rem;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
  }
  .content .big_things_wrapper:hover{
    box-shadow: 1px 4px 15px rgb(75 174 194 / 32%);
  }
  .el-timeline{
    padding: 2rem;
  }
  .el-timeline-item{
    font-size: 1.3rem;
  }
}
.up{
  background-color: rgba(255, 255, 255, 0.5);
}
</style>